.box40 {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.box41 {
    display: flex;
    gap: 5px;
    justify-content: space-between;
}

.box43 {
    display: flex;
    flex-direction: column;
    
}

.box44 {
    display: flex;
    /* width: 255px; */
    padding: 12px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #F4F7FF;
    margin-top: 10px;
    }

.box42 {
    border-radius: 8px;
    border: 1px solid var(--form-stroke, #E7E7E7);
    display: flex;
    padding: 8px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    flex: 1 0 0;
    margin-top: 10px;
}

.box45 {
    width: inherit;
    margin-left: 10px;
}

.text-r {
    text-align: right;
    width: max-content;
}

.text-b {
    color: #080D2B;
}

.text-green {
    color: #27AE60;
}

.css-3ipe9i-MuiGrid-root>.MuiGrid-item {
    margin-top: 0% !important;
}

.text60 {
    font-family: font;
    font-size: 1rem;
    margin-bottom: 10px;
    color: #014E97;
    /* font-weight: 700; */
}

.box61 {
    display: flex;
    border-radius: 8px;
border: 1px solid var(--form-stroke, #E7E7E7);
margin-top: 10px;
padding: 5px;
}

.box62 {
    min-width: max-content;
    border-left: solid 1px #E7E7E7;
    padding: 10px;
}

.pt0 {
    padding-top: 0px !important;
}