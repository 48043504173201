.mobile-root {
    background: linear-gradient(163deg, #004A98 2.33%, #350055 153.89%) !important;
    /* background-color: linear-gradient(180deg, #004A98 0%, #350055 100%);  */
}

.active-mobile {
    color: #014A97 !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    margin: 10px 20px !important;
    font-family: font !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
  
.active-icone-mobile {
    color: #014A97!important;
    filter: invert(85%) sepia(46%) saturate(3508%) hue-rotate(200deg) brightness(100%) contrast(100%) !important;
}

.reverse-mobile {
    color: #fff !important;
    /* background-color: transparent !important; */
    font-family: font !important;
    
    border-radius: 5px !important;
    
    margin: 10px 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
  
.reverse-icone-mobile {
    color: #fff !important;
    /* filter: opacity(0.5) drop-shadow(0 0 0 #866 ) brightness(0.9) invert(1) contrast(200%) saturate(0.9);  */
    
}