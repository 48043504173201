.pro-img {
	width: 4.125vmax;
	height: 4.125vmax;
	border-radius: 3.125rem;
	border: 1px solid var(--form-stroke, #e7e7e7);
}

.pro-box {
	display: flex;
	height: 3.125vmax;
	justify-content: center;
	align-items: center;
	gap: 1.5vmax;
}
