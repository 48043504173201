.app-bar {
  display: flex;
  width: 100%;
  padding: 0.5rem 0rem;
  justify-content: space-around;
  align-items: center;
  background: rgba(1, 78, 151, 0.2);
  backdrop-filter: blur(20px);
  z-index: 999;
  position: fixed;
  direction: rtl;
}

.app-bar-top {
  display: flex;
  width: 100%;
  padding: 0.5rem 0rem;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  z-index: 999;
  position: fixed;
  direction: rtl;
}

.app-bar-footer {
  display: flex;
  width: 100%;
  padding: 0.5rem 0rem;
  justify-content: space-around;
  align-items: center;
  background: #14124c;
  backdrop-filter: blur(20px);
  z-index: 999;
  /* position: fixed; */
  direction: rtl;
}

.footer-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.footer-text {
  display: flex;
  /* width: 7.625rem; */
  height: 1.4375rem;
  flex-direction: column;
  justify-content: center;

  color: #fff;
  text-align: right;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 0.875vmax;
  font-style: normal;
}

.footer-icons {
  display: flex;
  align-items: flex-start;
  gap: 0.4375rem;
}

.hero-section {
  /* background: url("../../assets/heroBG.png"), lightgray; */
  background: url("../../assets/landingvideo.mp4"),
    lightgray -170px 0px / 127.349% 123.079% no-repeat;
  background-size: 1450px;
  background-position: center;
  /* object-fit: cover; */
  /* width: 100vw; */
  height: 100vh;
  flex-shrink: 0;
  position: relative;
}

.video-bg {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  position: absolute;
}

.hero-root {
  width: 100%;
  height: 100vh;
  flex-shrink: 0;
  opacity: 0.79;
  background: linear-gradient(
    333deg,
    #0e005d 0%,
    rgba(0, 74, 152, 0.87) 100.95%
  );
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 1;
  top: 0;
}

.hero-text-1 {
  color: #fff;
  text-align: right;
  font-family: font;
  font-size: 4.444vmax;
  font-style: normal;
  /* font-weight: 750; */
  /* line-height: 6rem;  */
  z-index: 99;
  /* position: absolute; */
  width: 44.444vw;
  font-family: Zarid;
}

.hero-text-2 {
  color: #fff;
  text-align: right;
  font-family: font-light;
  font-size: 1.567vmax;
  font-style: normal;
  /* font-weight: 400; */
  /* line-height: 2.55rem;  */
  margin-top: 2rem;
  width: 40vw;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .hero-text-1 {
    width: 90vw;
    font-size: 6vmax;
  }
  .hero-text-2 {
    margin-top: 5vh;
    width: 90vw;
    font-size: 2.7vmax;
  }
  .img-section {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .img-section-1 {
    width: 90vw;
  }
  .img-section-2 {
    width: 50vw;
  }
  /* .ForWho-sec2 {
    transform: scale(0.5); */
  /* margin-top: -75px !important;
  } */
  .ForWho-root {
    height: auto !important;
  }

  .section-2 {
    height: auto !important;
  }
  .section-text-1 {
    font-size: 1.7vmax;
    font-style: normal;
  }
}

@media only screen and (max-width: 900px) {
  .ForWho-root {
    height: auto !important;
  }

  .section-2 {
    height: auto !important;
  }

  .ForWho-sec2 {
    width: 90vw !important;
  }

  .ForWho-sec2-tab {
    margin-right: 0 !important;
  }
  .hero-text-1 {
    width: 90vw !important;
    font-size: 6vmax;
  }
  .hero-text-2 {
    margin-top: 5vh;
    width: 90vw;
    font-size: 2.7vmax;
  }
  .img-section {
    margin-top: 80px;
  }
  .box-l-1 {
    margin-top: auto;
  }
}

.section-2 {
  width: 100%;
  height: 80vh;
  direction: rtl;
  /* margin-top: 2rem; */
  background: #e6edf5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-text-1 {
  color: var(--Gray-2, #4f4f4f);
  text-align: right;
  font-size: 1.125rem;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 1.9125rem; /* 170% */
}

.s2-text1 {
  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: right;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 1.5rem;
  font-style: normal;
}

.box-l-1 {
  display: flex;
  width: 29rem;
  padding: 1rem;
  flex-direction: column;
  /* align-items: flex-end; */
  gap: 1rem;
  direction: rtl;

  border-radius: 0.5rem;
  border: 1px solid rgba(67, 122, 179, 0.08);
  background: #fff;
  box-shadow: 0px 18px 32px 0px rgba(0, 74, 152, 0.09);
}

.img-section {
  display: flex;
  align-items: center;
  position: relative;
}

.img-section-1 {
  max-width: 50vmax;
}

.f-light {
  font-family: font-light !important;
}

.ForWho-root {
  height: 95vh;
  margin-top: 15vh;
  /* justify-content: center; */
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ForWho-text-root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ForWho-text-1 {
  display: flex;
  width: 18.75rem;
  height: 3.875rem;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 2.25rem;
  font-style: normal;
  line-height: 6rem;

  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ForWho-text-2 {
  display: flex;
  width: 18.75rem;
  height: 1.75rem;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 1rem;
  font-style: normal;
  line-height: 6rem;

  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ForWho-sec2 {
  display: flex;
  justify-content: center;
  direction: rtl;
  width: 70vw;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ForWho-sec2-btns {
  display: flex;
  /* width: 22.375rem; */
  padding: 0.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
  align-self: stretch;
}

.ForWho-acc-unfocused {
  display: flex;
  height: 4.375rem;
  padding: 0.5rem 1rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  cursor: pointer;

  border-radius: 0.5rem;
  border: 1px solid rgba(67, 122, 179, 0.08);
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 74, 152, 0.09);
}

.ForWho-acc-focused {
  height: 9.25rem;
  align-self: stretch;
  display: flex;
  justify-content: space-between;

  border-radius: 0.5rem;
  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  box-shadow: 0px 16px 32px 0px rgba(0, 74, 152, 0.09);
}

.ForWho-text-3 {
  color: #437ab3;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 1.5rem; /* 120% */
}

.ForWho-text-4 {
  color: #fff;
  text-align: right;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.5rem; /* 100% */
  margin-top: 1rem;
  margin-right: 1rem;
}

.ForWho-text-5-con {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  border-radius: 0.25rem;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
}

.ForWho-text-5 {
  color: #004a98;
  text-align: right;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.5rem; /* 100% */
}

.ForWho-text-6-con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.ForWho-text-6 {
  display: flex;
  /* height: 5.25rem; */
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: var(--Gray-2, #4f4f4f);
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.9125rem; /* 191.25% */
}

.ForWho-text-7con {
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  flex-direction: row;
  cursor: pointer;
}

.ForWho-text-7 {
  color: var(--foundation-blue-b-300, #004a98);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.5rem; /* 171.429% */
}

.ForWho-text-8 {
  color: #004a98;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem; /* 150% */
}

.ForWho-text-9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--Gray-2, #4f4f4f);
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.9125rem; /* 191.25% */
}

.ForWho-text-10 {
  color: var(--foundation-blue-b-50, #e6edf5);
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem; /* 150% */
}

.frame-img {
  width: 6.5rem;
  height: 6.48813rem;
  flex-shrink: 0;
  justify-self: flex-end;
  margin-top: 1rem;
}

.ForWho-sec2-tab {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  flex: 1 0 0;
  margin-right: 20px;
  max-height: max-content;

  border-radius: 0.5rem;
  border: 1px solid rgba(67, 122, 179, 0.08);
  background: #fff;
  box-shadow: 0px 18px 32px 0px rgba(0, 74, 152, 0.09);
}

.frame13 {
  display: flex;
  padding: 0.5rem 0rem;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  border-radius: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.frame14 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  align-self: stretch;
}

.frame15 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  align-self: stretch;
}

.frame16 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
}

.frame22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.icon-frame {
  display: flex;
  align-items: flex-end;
  gap: 0.625rem;

  border-radius: 62.4375rem;
  background: var(--foundation-blue-b-300, #004a98);
}

.ForWho-btn1 {
  display: flex;
  width: 12.0625rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;

  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: var(--foundation-blue-b-300, #004a98);
  box-shadow: 0px 14px 26px 0px rgba(0, 28, 194, 0.18);
}

.ForWho-shape {
  width: 29.95413rem;
  height: 31.57138rem;
  transform: rotate(85.687deg);
  flex-shrink: 0;
  position: absolute;
  z-index: -99;
  right: 20vw;
  top: 20px;
  /* left: 0; */

  border-radius: 31.57138rem;
  /* opacity: 0.56; */
  /* background: #EEF2F7; */
}
