.renter-req-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 25vh;
  gap: 30px;
}

.box10 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.box11 {
  padding: 20px;
  min-width: 10vw;
  display: flex;
  flex-direction: column;
  direction: rtl;
}

.box12 {
  height: 25vh;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed #d8d8d8;
  background: #f5f7ff;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box13 {
  fill: #014e97;
  background-color: #014e97;
  stroke-width: 1px;
  stroke: var(--Stroke, #e7e7e7);
  width: 40.537px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.req-root {
  margin-inline: 20px;
}

.box14 {
  /* min-width: 100%; */
  height: 100%;
  background-color: #fff;
  /* margin: 2rem; */
  margin-top: 1rem;
  /* margin-left: 0%; */
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box15 {
  min-width: 80%;

  /* height: 100%; */
  background-color: #f1f4ff;
  /* margin: 2rem; */
  margin-top: 1rem;
  margin-left: 20px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box15-1 {
  min-width: 80%;
  background-color: #f1f4ff;
  /* margin-top: 1rem; */
  /* margin-left: 20px; */
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box16 {
  min-width: 80%;

  /* height: 100%; */
  background-color: #014e97;
  /* margin: 2rem; */
  margin-top: 1rem;
  margin-right: 20px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}
.box16-green {
  min-width: 97%;
  /* height: 100%; */
  background-color: #219653;
  /* margin: 2rem; */
  margin-top: 1rem;
  margin-right: 20px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.box17 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box18 {
  min-width: 80%;

  /* height: 100%; */
  background-color: #f1f4ff;
  /* margin: 2rem; */
  margin-top: 1rem;
  margin-left: 20px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box19 {
  display: flex;
  width: 120px;
  height: 50px;
  /* padding: 12px; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--form-stroke, #e7e7e7);
}

.box20 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.box30 {
  display: flex;
  height: 54px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #014a97;
  text-align: center;
  font-family: font;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 700; */
  /* line-height: 78px; */
}

.box31 {
  width: 300px;
  height: 50px;
  background-color: #f1f4ff;
  display: flex;
  margin-right: 20px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.box32 {
  display: flex;
}

.box33 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--form-stroke, #e7e7e7);
  background: #fff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 50px;
}

.box34 {
  margin-top: 20px;
  background-color: #fff;
  border: none;
  /* padding-block: 20px; */
  width: auto;
}

.shape10 {
  background-color: #83a8cf;
  border-radius: 50%;
}

.text20 {
  color: rgba(1, 28, 80, 0.65);
  text-align: center;
  font-family: font;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 27.2px; /* 170% */
}

.text21 {
  color: #004a98;
  text-align: center;
  font-family: font;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 700; */
}

.text22 {
  text-align: right;
  font-family: font;
  font-size: 1rem;
  font-style: normal;
  /* font-weight: 600; */
  display: flex;
  margin-top: 0%;
  margin-right: 5%;
  text-align: center;
  color: #080d2b;
}

.text23 {
  text-align: right;
  font-family: font;
  font-size: 0.85rem;
  font-style: normal;
  /* font-weight: 500; */
  display: flex;
  margin-top: 0%;
  margin-right: 5%;
  text-align: center;
  color: #4f4f4f;
}

.text23-1 {
  text-align: right;
  font-family: font;
  font-size: 0.85rem;
  font-style: normal;
  /* font-weight: 500; */
  display: flex;
  margin-top: 0%;
  /* margin-right: 5%; */
  margin-block: 0px 5px;
  text-align: center;
  color: #4f4f4f;
}

.text24 {
  color: #fff;
  text-align: right;
  font-family: font;
  font-size: 0.8rem;
  font-style: normal;
  /* font-weight: 500;/ */
  margin-right: 10px;
}

.text25 {
  text-align: right;
  font-family: font;
  font-size: 0.85rem;
  font-style: normal;
  /* font-weight: 500; */
  display: flex;
  margin-top: 0%;
  margin-right: 5%;
  width: max-content;
  color: #080d2b;
}

.text26 {
  text-align: right;
  font-family: font;
  font-size: 0.99rem;
  font-style: normal;
  /* font-weight: 600; */
  display: flex;
  margin-top: 0%;
  text-align: center;
  color: #080d2b;
}

.slb-text {
  font-size: 0.8rem;
  font-family: font;
  margin-bottom: 3px;
  margin-top: 5px;
}

.center {
  justify-content: start;
}

.normal-step {
  text-align: right;
  font-family: font;
  font-size: 0.6rem;
  font-style: normal;
  /* font-weight: 600; */
  display: flex;
  margin-top: 0%;
  /* margin-right: 5%; */
  text-align: center;
  /* color: #014a97; */
}

.countdown-timer {
  font-size: 1rem;
  /* color: burlywood; */
  font-family: font;
  display: flex;
  gap: 10px;
  direction: rtl;
  background-color: #f1f4ff;
  padding: 15px;
  border-radius: 6px;
}

.timer-min {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orange {
  color: #f2994a;
}

@media only screen and (max-width: 600px) {
  .renter-req-root {
    height: 78vh;
    margin-top: 0vh;
  }

  .displayNon-600 {
    display: none;
  }

  .box16 {
    min-width: auto;
  }

  .pay-icon {
    scale: calc(100vw / 1100);
  }
  .box19 {
    width: min-content;
  }
  .vw100 {
    width: 100vw;
  }
  .overF-auto {
    overflow: auto;
  }
  .mr0 {
    margin-right: 0px;
  }
  .fx-dir {
    flex-direction: column;
  }
  .s09 {
    scale: 0.9;
  }
  .mh-100vh {
    min-height: 100vh !important;
  }
  .w70 {
    width: 70% !important;
  }
}

.loading-dot {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 0 0 3ch);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 0 0 -1ch);
  }
}
