.partners-root {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-box {
  display: flex;
  width: 70rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.0625rem;
}

.partners-text {
  display: flex;
  width: 7.9375rem;
  height: 3.875rem;
  flex-direction: column;
  justify-content: center;

  color: #004a98;
  text-align: center;
  font-size: 2.25rem;
  font-style: normal;
  line-height: 6rem; /* 266.667% */
}

.partners-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.partners-box2 {
  display: flex;
  width: 72.8vw;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .partners-image {
    width: 15vw;
    /* margin-inline: 5px; */
    margin-right: 5px;
  }
  .partners-box2 {
    width: auto;
  }
}
/* test */

.p-img {
  max-width: 350px;
  height: 3rem;
  padding: 0 15px;
  object-fit: cover;
}

/* .p-img:hover {
  transform: scale(1.2);
} */

.p-img:last-of-type {
  padding-left: 0;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 5rem;
}

.wrapper {
  position: absolute;
  display: flex;
}

section {
  display: flex;
  /* animation: swipe var(--speed) linear infinite backwards; */
  animation: var(--swipe) var(--speed) linear backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
