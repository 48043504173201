.why-root {
  /* width: 96rem; */
  /* height: 55.8125rem; */
  height: 70vh;
  flex-shrink: 0;

  background: linear-gradient(265deg, #0e005d 21.22%, #004a98 86.38%);
  box-shadow: 0px 16px 32px 0px rgba(0, 74, 152, 0.09);
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.why-box1 {
  display: inline-flex;
  align-items: center;
  gap: 5.0625rem;
  /* width: 70vw */
  z-index: inherit;
  justify-content: center;
}

.why-box2 {
  width: 45.875rem;
  height: 42.375rem;
  display: flex;
  gap: 2rem;
}

.why-box3 {
  /* frame26*/
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.why-box4 {
  /* menu item*/
  display: flex;

  padding: 1.1vmax 1.6vmax;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 1px solid rgba(67, 122, 179, 0.08);
  background: #fff;
  box-shadow: 0px 18px 32px 0px rgba(0, 74, 152, 0.09);
}

.why-box5 {
  /* frame8*/
  display: flex;
  /* height: 3.4375rem; */
  padding: 0.5rem 0rem;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  border-radius: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.why-box6 {
  /* frame12*/
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.why-box7 {
  /* frame20*/
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  flex: 1 0 0;
  align-self: stretch;
}

.why-box8 {
  /* frame3*/
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.5rem;
}

.why-box9 {
  /* frame24*/
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.why-text1 {
  color: #004a98;
  text-align: right;
  /* font-size: 1.111vmax; */
  font-style: normal;
}

.why-text2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: var(--Gray-2, #4f4f4f);
  text-align: right;
  /* font-size: 1.111vmax; */
  font-style: normal;
}

.why-text3 {
  color: var(--foundation-blue-b-300, #004a98);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.5rem;

  opacity: 0.8;
}

.why-text4 {
  display: flex;
  width: 18.75rem;
  height: 3.875rem;
  flex-direction: column;
  justify-content: center;

  color: #fff;
  text-align: right;
  font-size: 2.25rem;
  font-style: normal;
  line-height: 6rem; /* 266.667% */
}

.why-text5 {
  width: 80%;

  color: #fff;
  text-align: right;
  font-size: 1.2rem;
  font-style: normal;
  line-height: 1.9125rem; /* 191.25% */
  margin-top: 32px;
}

.why-shape1 {
  width: 67.86681rem;
  height: 66.49119rem;
  transform: rotate(27.889deg);
  flex-shrink: 0;

  border-radius: 67.86681rem;
  opacity: 0.1;
  background: linear-gradient(
    118deg,
    rgba(238, 242, 247, 0.6) 16.73%,
    rgba(238, 242, 247, 0) 51.09%
  );
  position: absolute;
  right: -700px;
  /* bottom: 10px; */
  top: 100px;
}
.why-inner-shape1 {
  width: 57.86681rem;
  height: 56.49119rem;
  transform: rotate(27.889deg);
  flex-shrink: 0;

  border-radius: 67.86681rem;
  opacity: 0.1;
  background: transparent;
  position: absolute;
  right: -700px;
  /* bottom: 10px; */
  top: 100px;
}

.why-shape {
  position: absolute;
  right: 0;
  top: 100px;
}

.why-shape2 {
  position: absolute;
  /* right: 0; */
  bottom: 0;
  left: 0;
  z-index: 0;
}

.why-img {
  width: 185px;
  height: 124.505px;
  flex-shrink: 0;
}

@media only screen and (max-width: 900px) {
  .why-box9 {
    width: auto;
  }
}
