.box1 {
	/* min-width: 100%; */
	height: 100%;
	background-color: #fff;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-left: 0%;
	border-radius: 12px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: auto;
}

.box2 {
	border-radius: 12px;
	border: 1px dashed rgba(67, 122, 179, 0.6);
	width: 100%;
	margin-right: 3%;
	display: flex;
	padding: 10px;
}

.box3 {
	display: flex;
	align-items: center;
	min-width: max-content;
	justify-content: space-between;
}

.box4 {
	display: flex;
	align-items: center;
	justify-content: start;
	min-width: max-content;
	width: -webkit-fill-available;
	width: -moz-available;
}

.mr5 {
	margin-right: 5px;
	margin-bottom: 0px;
}

.text8 {
	text-align: right;
	font-family: font;
	font-size: 0.99rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	text-align: center;
	color: #080d2b;
	align-items: center;
}

.text9 {
	text-align: right;
	font-family: font;
	font-size: 0.85rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #080d2b;
}

.text10 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #014a97;
}

.text11 {
	text-align: right;
	font-family: font;
	font-size: 0.7rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #080d2b99;
}

.text12 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #1a1a1a;
}

.text13 {
	font-family: font;
	font-size: 0.7rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	text-align: center;
	color: #014a97;
}

.text14 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 2%;
	text-align: center;
	color: #1a1a1a;
}

.border {
	border-bottom: 1px solid var(--form-stroke, #e7e7e7);
	height: 2px;
	width: 100%;
}

.shape1 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #27ae60;
	margin-top: 1%;
}

.shape2 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #ffc300;
	margin-top: 1%;
}

.shape3 {
	width: 8px;
	height: 8px;
	border-radius: 20px;
	background: var(--form-stroke, #a7a5a5);
}

.shape4 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #ff1f00;
	margin-top: 1%;
}

.h0 {
	height: auto;
}

.width-available {
	width: -webkit-fill-available;
	width: -moz-available;
}

.flex-center {
	display: flex;
	align-items: center;
	/* justify-content: center; */
}

@media only screen and (max-width: 390px) {
	.wc400px {
		margin-right: 20px !important;
	}
	/* .box1 {
        transform: scale(0.85);
    } */
	.css-jhuyf4-MuiGrid-root > .MuiGrid-item {
		padding-left: 0px !important;
	}
	.un-scale {
		transform: scale(0.85);
	}
}

.css-1blkw4v-MuiResponsiveChart-container {
	height: 100px !important;
	width: 200px !important;
	/* margin-top: 0%; */
}

.css-155wf22 {
	height: 100% !important;
	width: 100% !important;
}

.css-gp36re-MuiGrid-root > .MuiGrid-item {
	padding-top: 0px !important;
}
