.q-root {
	direction: rtl;
	height: 40vmax;
	display: flex;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.q-box1 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.q-box2 {
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	flex-direction: column;
	height: 70%;
}

.q-box3 {
	display: inline-flex;
	padding: 1.5rem;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 1rem;
	border-radius: 0.5rem;
	border: 1px solid rgba(67, 122, 179, 0.4);
	background: #fff;
	box-shadow: 0px 18px 32px 0px rgba(0, 74, 152, 0.09);
}

.q-box4 {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.q-box5 {
	border-radius: 62.4375rem;
	background: #fff;
	box-shadow: 0px 16px 32px 0px rgba(0, 74, 152, 0.09);
	padding: 0.8rem;
	position: absolute;
	bottom: -50px;
	left: -4.5rem;
	width: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.q-border {
	height: 100%;
	border-left: dashed 1px rgba(78, 77, 77, 0.9);
	/* width: 0.5rem; */
	margin-inline: 0.8rem;
	position: relative;
}

.q-text1 {
	color: var(--Primary, #014e97);
	text-align: right;
	font-size: 1rem;
	font-style: normal;
	/* line-height: 1.9125rem;  */
}

.q-shape {
	height: 20px;
	width: 20px;
	background-color: #014e97;
	border-radius: 50%;
	margin-left: -1.4rem;
	z-index: 9;
}

.q-shape1 {
	height: 20px;
	width: 20px;
	background-color: #014e97;
	border-radius: 50%;
	margin-right: -1.4rem;
	z-index: 9;
}
