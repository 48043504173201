.text60 {
	font-size: 1rem;
}

.agree-box1 {
	border: 1px solid var(--form-stroke, #e7e7e7);
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row-reverse;
}

.agree-box2 {
	display: flex;
	gap: 1vmax;
	margin-right: 10px;
	margin-block: 10px;
}
.agree-box3 {
	display: flex;
	gap: 1vmax;
	margin-left: 10px;
	margin-block: 10px;
	align-items: center;
}

.agree-box4 {
	width: 100%;
	margin-block: 10px;
	margin-inline: 10px;
	direction: rtl;
}
