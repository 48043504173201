.features-root {
  display: inline-flex;
  /* padding: 3.889vw 14.444vw; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4375rem;
  background: linear-gradient(180deg, #e6edf5 0%, rgba(230, 237, 245, 0) 100%);
  width: -webkit-fill-available;
  width: -moz-available;
}

.features-box1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5rem;
}

.features-box2 {
  display: flex;
  /* width: 69.9375rem; */
  align-items: flex-start;
  /* gap: 2.3vw; */
  justify-content: space-around;
  margin-bottom: 10%;
  width: 85%;
}

.features-box3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.features-box4 {
  display: flex;
  padding: 1.1vmax 1.6vmax;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 1px solid #437ab3;
  background: linear-gradient(277deg, #0e005d 10.39%, #004a98 96.34%);
  box-shadow: 0px 18px 32px 0px rgba(0, 74, 152, 0.24);
}

.features-text1 {
  display: flex;
  /* width: 18.75rem; */
  /* height: 3.875rem; */
  flex-direction: column;
  justify-content: center;

  text-align: center;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 2.25rem;
  font-style: normal;
  line-height: 6rem; /* 266.667% */

  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-text2 {
  display: flex;
  width: 21.3125rem;
  height: 1.75rem;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 1rem;
  font-style: normal;
  line-height: 6rem; /* 600% */

  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-text3 {
  color: #fff;
  text-align: right;
  /* font-size: 1.111vmax; */
  font-style: normal;
}

.features-text4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;

  color: #fff;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.9125rem; /* 191.25% */
}

.features-icon {
  border-radius: 4px;
  border: 1px solid var(--form-stroke, #e7e7e7);

  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.fb-size {
  width: 25%;
}

@media only screen and (max-width: 600px) {
  .features-root {
    padding: 0;
  }
  .features-box2 {
    margin-inline: 10px;
    margin-bottom: 50px;
  }
}
