.pay {
  border: 2px solid #004a98;
}

#mysr-form-form-el#mysr-form-form-el#mysr-form-form-el.mysr-form-moyasarForm.mysr-form-fixedWidth {
  max-width: 100% !important;
  font-family: "font";
}

#mysr-form-form-el#mysr-form-form-el#mysr-form-form-el.mysr-form-moyasarForm[payment-form="true"]
  .mysr-form-label,
#mysr-form-form-el#mysr-form-form-el#mysr-form-form-el.mysr-form-moyasarForm[payment-form="true"]
  label.mysr-form-label {
  font-family: font;
}
