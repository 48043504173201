.box50 {
    
    width: -webkit-fill-available;
    width: -moz-available;
    height: min-content;

}

.box51{
    flex-direction: row;
}

.box52 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box53 {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    direction: rtl;
    padding: 20px;
    gap: 20px;
}

.box54 {
    display: flex;
    /* justify-content: start; */
    align-items: start;
    flex-direction: column;
}

.text50 {
    /* font-weight: 500; */
    font-size: 0.85rem;
}

.text55 {
    font-size: 1.5rem;
    border-bottom: solid 1px #E7E7E7;
    padding-bottom: 20px;
}

.text56 {
    text-align: right;

}

.box56 {
    padding: 10px;
    margin-left: 50px;
}

.rdw-block-wrapper {
    display: none !important;
}
.rdw-fontfamily-wrapper {
    display: none !important;
}
.rdw-fontsize-wrapper {
    display: none !important;
}
.rdw-colorpicker-wrapper {
    display: none !important;
}
.rdw-image-wrapper {
    display: none !important;
}
.rdw-emoji-wrapper {
    display: none !important;
}
.rdw-embedded-wrapper {
    display: none !important;
}

/* .bold {
    font-weight: 600;
} */